<template>
  <div class="hello">
    <div>
      <video
        id="localVideo"
        class="full"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        x5-video-player-fullscreen="true"
        loop
        muted
        autoplay
      ></video>
    </div>
    <div>
      <video
        id="removeVideo"
        class="right_top"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        x5-video-player-fullscreen="true"
        loop
        autoplay
      />
    </div>
    <div class="close" @click="closeDown">
      <img class="hangUpImg" src="../../assets/hangUp.png" alt="" />
    </div>
    <div class="time" v-if="!loading">
      {{ time }}
    </div>
    <div class="loading" v-if="loading">
      <img src="../../assets/call.png" alt="" />
      <p>正在呼叫值班室···</p>
    </div>
  </div>
</template>

<script src="http://res.wx.qq.com/open/js/jweixin-1.4.0.js"></script>
<script src="https://cdn.bootcss.com/jquery/3.4.1/jquery.js"></script>
<script src="mobile-detect.js"></script>
<script src="code.js"></script>

<script>
import kurentoUtils from 'kurento-utils'
import { Dialog,Toast } from "vant";
import dayjs from "dayjs";
let ws
export default {
   components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      heartTimer:null,
      isFirst: true,
      isMute: false,
      isChange: false,
      loading: true,
      webRtcPeer: null,
      localStream: null,
      time: null,
      timeout: null,
      isInit: false,
      domain: process.env.VUE_APP_TEST_DOMAIN,
      openVidep:true,
      mute:false,
      callStartTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      isStatus:'0',
      type:'',
      id:''
    }
  },
  async created(){
    this.type = this.$route.query.type
    this.id = parseInt(Math.random()*100000)+100000
    console.log(this.type);
  },
  async mounted() {
    await this.init()
  },
  watch: {
    isInit: {
      handler(v) {
        if (v) {
          this.send()
        }
      },
    },
    loading:{
      handler(v){
        if(!v){
          var system = navigator.userAgent.toLowerCase();
          //判断android ios windows
          var iphone = system.indexOf("iphone");
          var ipad = system.indexOf("ipad");
          var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
          if (iphone !== -1 || ipad !== -1 || isMac) {
            this.iosPrompt()
          }
        }
      }
    }
  },
  methods: {
    // ios提示
    iosPrompt() {
      Dialog.alert({
        message: '当前网络不稳定'
      })
        .then(() => {
        let localVideo=document.getElementById('localVideo')
        let removeVideo=document.getElementById('removeVideo')
          localVideo.play()
          removeVideo.play()
      });
    },
    // 结束通话
    closeDown() {
      let toData = {
        roomId: `${localStorage.getItem('plateNo')}:${localStorage.getItem('itemId')}:${this.id}`,
        type: 0,
      }
      let mes = {
        action: 'ExitVideoRoom',
        data: toData,
      }
      if(this.type == 1){
        clearInterval(this.timeout)
        if(this.localStream){
          this.localStream.getTracks().forEach(item=>{
            item.stop()
          })
        }
        WeixinJSBridge.call("closeWindow");
      } else if(this.type == 2){
        this.$router.push({
          path: '/outpark/blankBoxCharging',
        })
      }
      clearTimeout(this.heartTimer)
      ws.close()
    },
    timer() {
      // 定义计时器变量
      let seconds = 0
      let minutes = 0
      let hours = 0
      this.timeout = setInterval(() => {
        seconds++
        if (seconds === 60) {
          seconds = 0
          minutes++
        }
        if (minutes === 60) {
          minutes = 0
          hours++
        }
        // 格式化时间
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        // 更新计时器显示
        this.time = formattedTime
      }, 1000)
    },
    reconnect() {
      // 添加断开重连的逻辑
      setTimeout(function () {
        console.log("尝试重新连接...");
        this.init();
      }, 3000); // 3秒后进行重连，可以根据需要进行调整
    },
    init() {
      ws = new WebSocket('wss://p2demo.cwwuyou.com/cloudSeats/service')
      const _this = this
      ws.onmessage = function (msg) {
        var message = JSON.parse(msg.data)
        if (!_this.isInit) {
          _this.isInit = true
        }
        switch (message.action) {
          case 'InitialComplete':
            _this.call()
            break
          case 'PointAnswer':
            _this.webRtcPeer.processAnswer(message.data)
            break
          case 'PointIce':
            _this.webRtcPeer.addIceCandidate(message.data)
            break
          case 'UserExitRoom':
            // 客服已离开
            Toast("客服已离开");
            _this.localStream?.getTracks().forEach(item=>{
              item.stop()
            })
            if(_this.type == 1 ){
              WeixinJSBridge.call("closeWindow");
            }else{
              _this.$router.push({
                path: '/outpark/blankBoxCharging',
              })
            }
            break;
          case 'VideoJoinRoomError':
            // 加入房间失败,请检查参数,或者房间用户已存在
            Toast("加入房间失败");
            break;
          case 'UserStatusError':
            // 您当前已存在在别的通话请求中,请先其他退出房间
            Toast("您当前已存在在别的通话请求中,请先其他退出房间");
            break;
          case 'UserJoinRoom':
            // data: 客服已加入房间
            Toast("客服已加入房间");
            if(!_this.timeout){
              _this.timer()
            }
            break;
        }
        ws.onerror = function(msg){
          this.reconnect()
        }
      }
    },
     sendHeartbeat() {
      clearTimeout(this.heartTimer);
      console.log("💓");
      ws.send("ping");

      // 定时发送心跳消息
      this.heartTimer = setTimeout(this.sendHeartbeat, 50000); // 5秒发送一次心跳消息，可以根据需要进行调整
    },
    call() {
      let lotType = localStorage.getItem("type")
      const params = {
        itemId: localStorage.getItem('itemId'),
        appId: localStorage.getItem('appId'),
        inletExitType: localStorage.getItem('inletExitType'),
        boxType: localStorage.getItem('boxData'),
        callNumber: `${localStorage.getItem('plateNo')}:${localStorage.getItem('itemId')}:${this.id}`,
        openId:localStorage.getItem('openId'),
        chanUuid:localStorage.getItem('chanUuid')
      }
      if (lotType == 12) {
        params.inletExitType = params.inletExitType * 1
        params.boxType = params.boxType * 1
        params.orderNo = null
      }
      if (lotType == 13 || lotType == 14) {
        params.orderNo = localStorage.getItem('orderNo')
        params.inletExitType = params.inletExitType * 1
        params.boxType = params.boxType * 1
      }
      let _this = this
      this.$axios.post(`${this.domain}/parklot/api/cloudseat/callup`, params).then((res) => {
        if (res.data.code == 200) {
          _this.start()
        }else{
          // Dialog.alert({
          //   message: '请求失败请重新扫码'
          // })
          JSON.stringify(alert('请求失败请重新扫码'))
          WeixinJSBridge.call("closeWindow");
        }
      }).catch(err=>{
          // Dialog.alert({
          //   message: '请求失败请重新扫码'
          // })
          JSON.stringify(alert('请求失败需要重新扫码'))
          WeixinJSBridge.call("closeWindow");
      })
    },
    send() {
      let toData = {
        roomId: `${localStorage.getItem('plateNo')}:${localStorage.getItem('itemId')}:${this.id}`,
        isJoin: true,
        name: `${localStorage.getItem('plateNo')}`,
        type: 0,
      }
      let mes = {
        action: 'VideoCreateRoom',
        data: toData,
      }
      ws.send(JSON.stringify(mes))
      this.sendHeartbeat();
    },
    async start() {
      let height = window.screen.availHeight
      let width = window.screen.availWidth
      let _this = this
      _this.localStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })

      //准备相关参数
      var option = {
        //本地的video标签对象
        localVideo: document.getElementById('localVideo'),
        remoteVideo: document.getElementById('removeVideo'),
        //设置响应的视频流
        videoStream: this.localStream,
        //ice候选者的回调方法
        onicecandidate: this.onIceCandidate,
        mediaConstraints: {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
          mandatory: {
            // 设置视频编解码器
            OfferToReceiveVideo: 'H264',
          },
        },
        //stun服务器地址
        configuration: {
          iceServers: [
            {
              urls: "stun:120.77.35.22:3478",
            },
            {
              urls: "turn:120.77.35.22:3478",
              username: "user",
              credential: "123456"
            },
          ],
        },
      }
      _this.webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
        option,
        function (error) {
          if (error) {
            return console.error(error)
          }
          _this.webRtcPeer.generateOffer(_this.onOfferPresenter)
        }
      )
    },
    onIceCandidate(ice) {
      console.log('收到ice ====> ' + ice)
      var toDate = {
        roomId: `${localStorage.getItem('plateNo')}:${localStorage.getItem('itemId')}:${this.id}`,
        icedidates: ice,
      }
      var msg = {
        action: 'SendIce',
        data: toDate,
      }
      this.loading = false
      
      ws.send(JSON.stringify(msg))
    },
    onOfferPresenter(error, offerSdp) {
      if (error) {
        //提案创建有问题
        return console.error(error)
      }
      var toData = {
        roomId: `${localStorage.getItem('plateNo')}:${localStorage.getItem('itemId')}:${this.id}`,
        type: 0,
        offer: offerSdp,
      }
      var msg = {
        action: 'sendOffer',
        data: toData,
      }
      ws.send(JSON.stringify(msg))
    },
  },
}
</script>

<style scoped lang="scss">
.full {
  height: 100vh ;
  width: 100vw;
  object-fit: fill;
}
.right_top {
  height: 200px;
  width: 140px;
  position: absolute;
  right: 3%;
  top: calc(2% + 22px);
  object-fit: fill;
  z-index: 999;
  border-radius: 6px;
}
.button {
  width: 50px;
}
.iosMsk{
  border:1px solid #000;
}
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  img {
    width: 60px;
    height: 60px;
  }
  p {
    text-align: center;
  }
}
.close {
  position: fixed;
  left: 50%;
  top: 85%;
  transform: translateX(-50%);
  .hangUpImg {
    width: 70px;
    height: 70px;
  }
}
.time {
  position: fixed;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
  font-size: 20px;
  color: #ffffff;
}
</style>
